import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { WebformObject } from 'gatsby-drupal-webform'

import { Paragraph, ParagraphComponent } from '../types'
import { dimensions, media } from '../../../style/variables'
import { DrupalProcessedText, DrupalMedia, DrupalMediaImage, DrupalColor } from '../../../drupal'
import { backgroundColorStyle } from '../../../utils'
import BackgroundImage from '../../fields/BackgroundImageWrapper'
import Media from '../../fields/Media'
import HeroWebform, { ExtraProps as WebformExtraProps } from './HeroWebform'

export interface HeroFormParagraph extends Paragraph {
	field_background_color?: DrupalColor
	field_body?: DrupalProcessedText
	field_body_extra?: DrupalProcessedText
	field_options?: string
	relationships: {
		field_media_any: DrupalMedia
		field_media_image?: DrupalMediaImage
		field_webform: WebformObject
	}
}

const StyledContainer = styled.div`
	/** Changes button color.  */
	--clr-primary: var(--clr-secondary);

	${media.up('md')} {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.media-graphics img {
		width: 100%;
	}

	/** media on left side */
	.hero-content-container {
		color: black;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		.hero-text,
		.media-graphics {
			padding: ${dimensions.containerPadding}rem 0;
		}

		.media-graphics {
			min-width: 300px;
			width: 20vw;

			img {
				width: 100%;
			}
		}
	}

	.hero-form-container {
		padding: ${dimensions.containerPadding}rem;
		position: relative;
		max-width: 800px;
		z-index: 20;
	}

	${media.up('md')} {
		.hero-form-container {
			padding: ${2 * dimensions.containerPadding}rem;
		}

		.hero-form-background {
			position: relative;

			&::before {
				content: '';
				clip-path: polygon(1.5rem 0%, 0% 100%, 100% 100%, 100% 0%);
				background-color: rgb(var(--clr-background));
				position: absolute;
				width: 80vw;
				height: 100%;
				left: 0rem;
				top: 0;
				z-index: 10;
			}
		}
	}

	${media.down('sm')} {
		.hero-content-container {
			padding: ${dimensions.containerPadding * 3}rem 0;
		}

		.hero-form-background {
			position: relative;

			&::before {
				content: '';
				clip-path: polygon(0% 100%, 100% 100%, 100% 0%);
				background-color: rgb(var(--clr-background));
				position: absolute;
				z-index: 10;
				top: calc(-1.5rem + 1px);
				left: calc(0% - (100vw - 100%) / 2);
				height: 1.5rem;
				width: 100vw;
			}
		}
	}
`

const HeroBackground = styled(BackgroundImage)`
	${media.up('md')} {
	}
`

function renderContent(side: 'left' | 'right', paragraph: HeroFormParagraph) {
	const {
		field_options: options,
		field_body: fieldBody,
		relationships: { field_media_any: fieldMedia }
	} = paragraph

	if (
		fieldBody &&
		((side === 'left' && options === 'text_on_left') || (side == 'right' && options === 'text_on_right'))
	) {
		return <div className="hero-text" dangerouslySetInnerHTML={{ __html: fieldBody.processed }} />
	} else if (fieldMedia) {
		return <Media className="hero-media" source={fieldMedia} />
	}
}

const HeroForm: ParagraphComponent<'paragraph__hero_form', HeroFormParagraph, WebformExtraProps> = ({
	paragraph,
	...props
}) => {
	const {
		field_background_color: backgroundColor,
		field_body_extra: fieldBodyExtra,
		relationships: { field_media_image: fieldBackgroundImage, field_webform: fieldWebform }
	} = paragraph

	return (
		<section
			className="paragraph full-width"
			style={{ position: 'relative', ...backgroundColorStyle(backgroundColor) }}
		>
			<StyledContainer>
				<HeroBackground image={fieldBackgroundImage}>{renderContent('left', paragraph)}</HeroBackground>
				<div className="hero hero-form-background">
					<div className="hero-form-container">
						{renderContent('right', paragraph)}
						<HeroWebform webform={fieldWebform} paragraph={paragraph} {...props} />
						{fieldBodyExtra && (
							<div className="hero-text" dangerouslySetInnerHTML={{ __html: fieldBodyExtra.processed }} />
						)}
					</div>
				</div>
			</StyledContainer>
		</section>
	)
}

export default HeroForm

export const query = graphql`
	fragment HeroFormParagraph on paragraph__hero_form {
		drupal_internal__id
		field_background_color {
			color
		}
		field_body {
			processed
		}
		field_body_extra {
			processed
		}
		field_options
		relationships {
			field_media_any {
				__typename
				... on media__image {
					...MediaImageDefault
				}
				... on media__graphics {
					...MediaGraphics
				}
				... on media__remote_video {
					...MediaRemoteVideo
				}
			}
			field_media_image {
				...MediaBackgroundImage
			}
			field_webform {
				...HeroWebform
			}
		}
	}
`
