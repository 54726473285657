import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import { Paragraph, ParagraphComponent } from './types'
import { DrupalProcessedText } from '../../drupal'
import Container from '../Container'
import { media } from '../../style/variables'
import { isParameter } from 'typescript'

export interface TitleParagraph extends Paragraph {
	field_paragraph_title: DrupalProcessedText
}

const StyledContainer = styled(Container)`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0;
	.title {
		margin-bottom: 0;
		font-size: 22px;
		padding: 0 10px;
		${media.up('sm')} {
			font-size: 24px;
			padding: 0 10px;
		}
		${media.up('md')} {
			font-size: 26px;
			padding: 0;
		}
		${media.up('lg')} {
			font-size: 28px;
			margin-top: 20px;
			padding: 0;
		}
	}
`

const Title: ParagraphComponent<'paragraph__title', TitleParagraph> = ({ paragraph }) => {
	/**
	 * Special hack to add typeform script to finnish website without creating
	 * a new paragraph. Magic value to title paragraph loads the script.
	 */
	const isSpecialHack = paragraph.field_paragraph_title?.processed === '<p>fi-typeform</p>'

	useEffect(() => {
		if (isSpecialHack) {
			const script = document.createElement('script')

			script.src = '//embed.typeform.com/next/embed.js'
			script.async = true

			document.body.appendChild(script)

			return () => {
				document.body.removeChild(script)
			}
		}
	}, [paragraph])

	if (isSpecialHack) {
		return <div data-tf-live="01HBRVF8CPACCYMJY0ZT8V8T6S"></div>
	}

	return (
		<section className="paragraph">
			<StyledContainer
				dangerouslySetInnerHTML={{ __html: paragraph.field_paragraph_title?.processed ?? '' }}
				className="title"
			/>
		</section>
	)
}

export default Title

export const query = graphql`
	fragment TitleParagraph on paragraph__title {
		drupal_internal__id
		field_paragraph_title {
			processed
		}
	}
`
