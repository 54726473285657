import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import { Paragraph, ParagraphComponent } from './types'
import { dimensions, media } from '../../style/variables'
import { DrupalMediaImage, DrupalColor } from '../../drupal'
import { backgroundColorStyle } from '../../utils'
import Container from '../Container'
import Media from '../fields/Media'

export interface LogosParagraph extends Paragraph {
	field_background_color?: DrupalColor
	field_title?: string
	relationships: {
		field_media: DrupalMediaImage[]
	}
}
const StyledLogoWrapper = styled.div`
	background-color: rgb(var(--clr-background));
	margin-top: auto;
	padding-top: ${dimensions.containerPadding}rem;
	padding-bottom: ${dimensions.containerPadding}rem;
	.text {
		text-align: center;
	}
`

/**
 * @see https://caniuse.com/#feat=css-grid
 */
const StyledContainer = styled(Container)`
	align-items: center;
	color: white;
	display: flex;
	justify-content: space-between;

	.media-logo {
		flex-grow: 1;
		text-align: center;
	}

	${media.up('lg')} {
		.logos {
			display: flex;
		}
	}
	${media.down('sm')} {
		flex-direction: column;
		text-align: center;

		.logos {
			padding-bottom: ${dimensions.containerPadding}rem;
		}

		.logos .gatsby-image-wrapper {
			display: block !important;
			margin: auto;
		}
	}
`

const Logos: ParagraphComponent<'paragraph__logos', LogosParagraph> = ({ paragraph }) => {
	return (
		<section className="paragraph full-width" style={backgroundColorStyle(paragraph.field_background_color)}>
			<StyledLogoWrapper>
				<div className="text">
					<h2>{paragraph.field_title}</h2>
				</div>
				<StyledContainer>
					{paragraph.relationships.field_media.map(logo => (
						<Media source={logo} key={logo.drupal_internal__mid} />
					))}
				</StyledContainer>
			</StyledLogoWrapper>
		</section>
	)
}

export default Logos

export const query = graphql`
	fragment LogosParagraph on paragraph__logos {
		drupal_internal__id
		field_background_color {
			color
		}
		field_title
		relationships {
			field_media {
				__typename
				...MediaLogo
			}
		}
	}
`
