import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layouts'
import { DrupalNode, DrupalTemplate } from '../drupal'
import { Microdata, getMetatagContent } from '../Microdata'

import Stack from '../components/Stack'
import Paragraphs from '../components/paragraphs/Paragraphs'
import { Paragraph } from '../components/paragraphs/types'

export interface LandingPageNode extends DrupalNode {
	relationships: {
		field_landing_paragraphs: Paragraph[]
	}
}

function makeLandingPageMicrodata(node: LandingPageNode): Microdata {
	return {
		'@context': 'http://schema.org',
		'@type': 'WebPage',
		name: getMetatagContent(node.metatag_normalized, 'name', 'title'),
		description: getMetatagContent(node.metatag_normalized, 'name', 'description')
	}
}
const LandingPageTemplate: DrupalTemplate<LandingPageNode> = ({ children, data: { node } }) => (
	<Layout node={node} microdata={makeLandingPageMicrodata(node)}>
		{/**
		 * HTML <main> tag spec:
		 *
		 * The content inside the <main> element should be unique to the
		 * document. It should not contain any content that is repeated across
		 * documents such as sidebars, navigation links, copyright
		 * information, site logos, and search forms.
		 *
		 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/main
		 * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Banner_role
		 *
		 * hero_banner paragraph kind of doesn't belong in <main>...
		 *
		 * Options:
		 *  Generate hero banner without paragraphs.
		 *    1) Checkbox or fields on landing_page -> add banner
		 *    2) Detect automatically: if (paragraphs[0].__typename !== 'paragraphs__hero_form')
		 *    3) Hack hero banner outside of main element: <Paragraphs paragraphs={[paragraphs[0]]} /> <main>...
		 *    4) Add type to gatsby-node UnionTypes
		 *
		 */}
		<main>
			<Stack css={{ overflowX: 'hidden' }}>
				<Paragraphs paragraphs={node.relationships.field_landing_paragraphs} />
				{children}
			</Stack>
		</main>
	</Layout>
)

export default LandingPageTemplate

export const pageQuery = graphql`
	query LandingPageTemplateQuery($drupalId: String!) {
		node: nodeLandingPage(drupal_id: { eq: $drupalId }) {
			title
			langcode
			metatag_normalized {
				tag
				attributes {
					name
					content
					property
					href
					rel
				}
			}
			relationships {
				field_landing_paragraphs {
					__typename
					... on paragraph__hero_form {
						...HeroFormParagraph
					}
					... on paragraph__hero_banner {
						...HeroBannerParagraph
					}
					... on paragraph__media {
						...MediaParagraph
					}
					... on paragraph__media_grid {
						...MediaGridParagraph
					}
					... on paragraph__call_to_action_single_column {
						...CallToAction1ColParagraph
					}
					... on paragraph__signup_count {
						...SignupCountParagraph
					}
					... on paragraph__title {
						...TitleParagraph
					}
					... on paragraph__featured_supporter {
						...FeaturedSupporterParagraph
					}
					... on paragraph__featured_content {
						...FeaturedContentParagraph
					}
					... on paragraph__logos {
						...LogosParagraph
					}
					... on paragraph__special_embed {
						...SpecialEmbedParagraph
					}
					... on paragraph__recipe_search {
						...RecipeSearchParagraph
					}
				}
			}
		}
	}
`
