import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import { getSrc } from 'gatsby-plugin-image'

import { media, dimensions } from '../../../style/variables'
import { DrupalNodeTeaser } from '../../../drupal'

export interface RecipeNodeTeaser extends DrupalNodeTeaser {
	relationships: {
		field_media: {
			relationships: {
				field_media_image: {
					localFile: {
						url: string
						childImageSharp: any
					}
				}
			}
		}
	}
}

interface Props {
	className?: string
	recipe: RecipeNodeTeaser
}

const RecipeCard: React.FC<Props> = ({ className, recipe }) => {
	const imageSrc = getSrc(recipe.relationships.field_media.relationships.field_media_image.localFile.childImageSharp)
	return (
		<Link className={className} to={recipe.path.alias}>
			<div
				className="card-img"
				style={{
					backgroundImage: `url(${imageSrc})`
				}}
			/>
			<div className="card-body">{recipe.title}</div>
		</Link>
	)
}

export default styled(RecipeCard)`
	background-color: #f9f7f7;
	font-weight: 600;
	color: black;
	text-align: center;

	${media.down('xs')} {
		font-size: 0.9rem;
	}
	height: 100%;
	display: flex !important;
	flex-direction: column;

	.card-img {
		height: 160px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;

		${media.up('sm')} {
			height: 180px;
		}

		${media.up('md')} {
			height: 220px;
		}
	}

	.card-body {
		flex: 1;
		padding: ${dimensions.containerPadding / 2}rem ${dimensions.containerPadding}rem;
		display: flex;
		flex-direction: column;
		justify-content: center;

		${media.down('xs')} {
			padding: ${dimensions.containerPadding / 2}rem ${dimensions.containerPadding / 2}rem;
		}
	}
`

export const query = graphql`
	fragment RecipeCard on node__recipe {
		drupal_internal__nid
		title
		path {
			alias
		}
		relationships {
			field_media {
				relationships {
					field_media_image {
						localFile {
							url
							childImageSharp {
								gatsbyImageData(height: 300)
							}
						}
					}
				}
			}
		}
	}
`
