import React, { useState } from 'react'
import { graphql, navigate } from 'gatsby'
import Webform, { WebformObject } from 'gatsby-drupal-webform'

/** @todo Formalize type exports in 'gatsby-drupal-webform' module and fix these (importing from /dist is a hack) */
import {
	WebformSuccessHandler,
	WebformErrorHandler,
	WebformCustomComponent,
	WebformSubmitHandler
} from 'gatsby-drupal-webform/dist/Webform'

import { featureTest } from '../../../utils'
import Stack from '../../StackRow'
import WebformRecaptcha from './WebformRecaptcha'
import WebformEntitySelect from './WebformEntitySelect'
import WebformLocationAutocomplete from './WebformLocationAutocomplete'
import WebformSelect from './WebformSelect'
import WebformSubmit from './WebformSubmit'
import { HeroFormParagraph } from './HeroForm'

const WebformCustomComponents = {
	simple_captcha: WebformRecaptcha,
	webform_entity_select: WebformEntitySelect,
	vc_geolocation: WebformLocationAutocomplete,
	select: WebformSelect,
	webform_actions: WebformSubmit
} as { [type: string]: WebformCustomComponent }

// Expose overwriting or extending webform functionality.
export interface ExtraProps {
	onSuccess: WebformSuccessHandler
	onSubmit?: WebformSubmitHandler
	customComponents: typeof WebformCustomComponents
	endpoint: string
}

interface Props {
	webform: WebformObject
	paragraph: HeroFormParagraph
}

const StyledWebform = Stack.withComponent(Webform)

const HeroWebform = ({ webform, paragraph, endpoint, onSuccess, onSubmit, customComponents }: Props & ExtraProps) => {
	const elementsCopy = [...webform.elements]
	const [webformError, setWebformError] = useState<string | undefined>(undefined)

	if (elementsCopy.findIndex(element => element.name === 'simple_recaptcha_token') >= 0) {
		const captchaElement = {
			name: 'simple_captcha',
			type: 'simple_captcha',
			attributes: []
		}

		// Insert `captcha` element after `simple_recaptcha_token` element.
		elementsCopy.splice(
			elementsCopy.findIndex(element => element.name === 'simple_recaptcha_token') + 1 || elementsCopy.length,
			0,
			captchaElement
		)
	}

	const onValidate = (event: React.FormEvent<HTMLFormElement>): boolean => {
		const form = event.currentTarget

		if (featureTest.localStorage()) {
			// Save email in localStorage for using on Thank you page.
			const emailquery = form.querySelector<HTMLInputElement>('input#form-email')
			const email = emailquery?.value

			window.localStorage.setItem('email', email || '')
		}

		// Client side validation to check if recaptcha is completed.
		const token = form.querySelector<HTMLInputElement>('input#form-simple-recaptcha-token')

		if (token) {
			/**
			 * This should not fail if everyting is configured correctly.
			 * However, if something fails here, this has a bad user experience.
			 * When this check fails, no errors are displayed and the submit
			 * button does nothing.
			 *
			 * The bypass option is useful for development environments. The
			 * captcha check is only removed from client side check and the
			 * backend should also be configured to ignore captcha.
			 */
			return !!process.env.GATSBY_BYPASS_RECAPTCHA || !!token.value // Return true if token has a value.
		}

		// Hack: make submit button disabled while form is submitting
		// This probalby should be done in gatsby-drupal-webform project.
		if (form.checkValidity()) {
			const button = form.querySelector('button#webform-submit') as HTMLButtonElement
			button.disabled = true
		}

		return true
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onError: WebformErrorHandler = err => {
		// Hack: make submit button enabled again.
		// This probalby should be done in gatsby-drupal-webform project.
		const button = document.querySelector('button#webform-submit') as HTMLButtonElement
		button.disabled = false

		if (err.response && err.response.data.error) {
			// Recaptcha error is inserted with key ''.
			setWebformError(err.response.data.error[''])
		}
	}

	return (
		<>
			<StyledWebform
				noValidate={true}
				className="hero-form"
				webform={{ ...webform, elements: elementsCopy }}
				extraData={{ entityType: 'paragraph', entityId: paragraph.drupal_internal__id }}
				customComponents={customComponents}
				endpoint={endpoint}
				onSuccess={onSuccess}
				// Not casting to any causes error here.
				// I think it is caused by styled hijacking onSubmit prop.
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				onSubmit={onSubmit as any}
				onValidate={onValidate}
				// Not casting to any causes error here.
				// I think it is caused by styled hijacking onSubmit prop.
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				onError={onError as any}
			/>

			{webformError}
		</>
	)
}

HeroWebform.defaultProps = {
	// Navigate to confirmation url by default.
	onSuccess: (response => {
		// onSuccess() is going to redirect to another page.
		// Don't re-enable form before redirect is done:
		// const button = document.querySelector('button#webform-submit') as HTMLButtonElement
		// button.disabled = false

		const { confirmation_url: confirmationUrl = '/' } = response.settings

		if (confirmationUrl.startsWith('http')) {
			window.location.href = confirmationUrl
		} else {
			// Redirect to internal url using Gatsby.
			navigate(confirmationUrl)
		}
	}) as WebformSuccessHandler,
	// Default endpoint = drupal
	endpoint: `${process.env.GATSBY_DRUPAL_HOST}/react_webform_backend/submit`,
	customComponents: WebformCustomComponents
}

export default HeroWebform

export const query = graphql`
	fragment HeroWebform on webform__webform {
		drupal_internal__id
		status
		elements {
			name
			type
			options {
				label
				value
			}
			attributes {
				name
				value
			}
			states {
				state
				selector
				condition {
					checked
				}
			}
		}
	}
`
