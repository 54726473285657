import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import RecipeSection from './RecipeSection'
import { RecipeNodeTeaser } from './RecipeCard'

interface StaticQueryProps {
	allTaxonomyTermRecipeTags: {
		nodes: {
			drupal_internal__tid: number
			name: string
			relationships: {
				node__recipe: RecipeNodeTeaser[] | null
			}
		}[]
	}
}

const Recipes: React.FC = () => {
	const { allTaxonomyTermRecipeTags }: StaticQueryProps = useStaticQuery(graphql`
		{
			allTaxonomyTermRecipeTags(sort: { fields: weight }, filter: { name: { ne: "Dummy tag" } }) {
				nodes {
					drupal_internal__tid
					name
					relationships {
						node__recipe {
							...RecipeCard
						}
					}
				}
			}
		}
	`)

	return (
		<section>
			{/**
			 * @TODO Search functionality here.
			 */}
			{allTaxonomyTermRecipeTags.nodes.map(
				group =>
					// Skip groups that have no recipes.
					Array.isArray(group.relationships.node__recipe) &&
					group.relationships.node__recipe.length && (
						<RecipeSection
							key={group.drupal_internal__tid}
							title={group.name}
							recipes={group.relationships.node__recipe}
						/>
					)
			)}
		</section>
	)
}

export default Recipes
