import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Slider from 'react-slick'

import { Paragraph, ParagraphComponent } from './types'
import { DrupalProcessedText, DrupalMediaImage, DrupalNodeTeaser } from '../../drupal'
import Media from '../fields/Media'
import Container from '../Container'
import { dimensions, media } from '../../style/variables'

// Import carousel css files
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export interface SupporterNode extends DrupalNodeTeaser {
	body?: DrupalProcessedText
	field_supporter_description: string
	relationships: {
		field_media: DrupalMediaImage
	}
}

export interface FeaturedSupporterParagraph extends Paragraph {
	relationships: {
		field_reference: SupporterNode[]
	}
}

/**
 * @see https://caniuse.com/#feat=css-grid
 */
const StyledSupporter = styled(Container)`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: ${dimensions.containerPadding}rem;

	${media.up('md')} {
		grid-template-columns: 1fr 2fr;
	}

	.image .gatsby-image-wrapper {
		border-radius: 50%;
	}

	.image,
	.content {
		align-self: center;
	}

	.description {
		color: rgb(var(--clr-secondary));
		font-size: ${dimensions.headingSizes.h4}rem;
		text-transform: uppercase;
		font-weight: bold;
	}
`

function renderOne(supporter: SupporterNode) {
	return (
		<StyledSupporter className="supporter">
			<Media source={supporter.relationships.field_media} />
			<article className="content">
				<span className="description">{supporter.field_supporter_description}</span>
				<h3>{supporter.title}</h3>
				{supporter.body && <div className="body" dangerouslySetInnerHTML={{ __html: supporter.body.processed }} />}
			</article>
		</StyledSupporter>
	)
}

/**
 * @see https://react-slick.neostack.com/docs/api/
 */
const SLICK_SETTINGS = {
	// autoplay: true,
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1
}

const FeaturedSupporter: ParagraphComponent<'paragraph__featured_supporter', FeaturedSupporterParagraph> = ({
	paragraph
}) => (
	<section className="paragraph">
		{paragraph.relationships.field_reference.length > 0 ? (
			// Render as carousel if has multiple items.
			<Slider {...SLICK_SETTINGS} css={SLICK_SETTINGS.dots && { marginBottom: '25px' }}>
				{paragraph.relationships.field_reference.map(supporter => (
					<React.Fragment key={supporter.drupal_internal__nid}>{renderOne(supporter)}</React.Fragment>
				))}
			</Slider>
		) : (
			// Render without carousel.
			paragraph.relationships.field_reference.map(renderOne)
		)}
	</section>
)

export default FeaturedSupporter

export const query = graphql`
	fragment FeaturedSupporterParagraph on paragraph__featured_supporter {
		drupal_internal__id
		relationships {
			field_reference {
				drupal_internal__nid
				title
				body {
					processed
				}
				field_supporter_description
				relationships {
					field_media {
						__typename
						...MediaImageMedium
					}
				}
			}
		}
	}
`
