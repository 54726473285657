import React from 'react'
import Recaptcha from 'react-recaptcha'
import { WebformCustomComponent } from 'gatsby-drupal-webform'

const WebformRecaptcha: WebformCustomComponent = () => {
	// render on captcha load
	const handleCaptchaLoad = () => {
		// console.log('handleCaptchaLoad')
	}

	// executed once the captcha has been verified
	const verifyCallback = (response: string) => {
		const tokenElement = document.getElementById('form-simple-recaptcha-token') as HTMLInputElement

		if (tokenElement) {
			tokenElement.value = response
		}
	}

	return (
		<Recaptcha
			sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
			verifyCallback={verifyCallback}
			onloadCallback={handleCaptchaLoad}
			render="explicit"
			hl={process.env.GATSBY_LANGUAGE}
		/>
	)
}

export default WebformRecaptcha
