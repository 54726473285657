import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import { Paragraph, ParagraphComponent } from './types'
import { DrupalMedia, DrupalProcessedText, DrupalColor, DrupalLink } from '../../drupal'
import { backgroundColorStyle } from '../../utils'
import FieldMedia from '../fields/Media'
import Container from '../Container'
import { dimensions, media } from '../../style/variables'
import Button from '../blocks/Button'

export interface MediaParagraph extends Paragraph {
	field_background_color?: DrupalColor
	field_body?: DrupalProcessedText
	field_link?: DrupalLink
	field_padding_top: number
	field_padding_bottom: number
	relationships: {
		field_media_any?: DrupalMedia
	}
}

/**
 * @see https://caniuse.com/#feat=css-grid
 */
const StyledContainer = styled(Container)`
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: ${dimensions.containerPadding}rem;

	${media.up('lg')} {
		grid-template-columns: 2fr 1fr;
	}

	/** @todo: better way to define colors buttons. */
	.btn,
	button {
		color: #fff;
	}

	.aside {
		align-self: center;
		padding: ${dimensions.containerPadding}rem 0;
	}

	${media.down('md')} {
		padding-top: 0 !important;
		padding-bottom: 0 !important;

		.aside {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
`

const Media: ParagraphComponent<'paragraph__media', MediaParagraph> = ({ paragraph }) => {
	const {
		field_body: fieldBody,
		field_background_color: fieldBackgroundColor,
		field_link: fieldLink,
		field_padding_top: paddingTop,
		field_padding_bottom: paddingBottom,
		relationships: { field_media_any: fieldMedia }
	} = paragraph

	return (
		<section className="paragraph full-width" style={backgroundColorStyle(fieldBackgroundColor)}>
			<StyledContainer style={{ paddingTop, paddingBottom }}>
				<div>{fieldMedia && <FieldMedia source={fieldMedia} />}</div>
				<div className="aside">
					{fieldBody && <div dangerouslySetInnerHTML={{ __html: fieldBody.processed }} />}
					{fieldLink && <Button link={fieldLink} />}
				</div>
			</StyledContainer>
		</section>
	)
}

export default Media

export const query = graphql`
	fragment MediaParagraph on paragraph__media {
		drupal_internal__id
		field_body {
			processed
		}
		field_background_color {
			color
		}
		field_link {
			uri
			alias
			title
		}
		field_padding_top
		field_padding_bottom
		relationships {
			field_media_any {
				__typename
				... on media__image {
					...MediaImageDefault
				}
				... on media__remote_video {
					...MediaRemoteVideo
				}
			}
		}
	}
`
